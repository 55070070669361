.international-rates {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background-color: #fafafa;
  padding: 1rem;
}

.country-form {
  display: grid;
  grid-template-columns: max-content 1fr;
  gap: 1rem;
  align-items: center;
}

.tariffs {
  display: flex;
  flex-direction: row;
  gap: 0.25rem;

  .price {
    display: flex;
    gap: 0.25rem;
    flex-direction: row;
    align-items: flex-end;
  }

  > * {
    flex: 1 1 auto;
    padding: 1rem;
    background-color: #eeeeee;
  }
}

@media (width <= 64em) {
  .tariffs {
    flex-direction: column;
  }
}

.search-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-align: center;
}

.international__loader {
  display: flex;
  align-items: center;
  justify-content: center;
}
